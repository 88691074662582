import { JackpotTier } from '../../components/GameWindow/components/SWJ/TopBar/jackpotTiers';
import { getAmplitudeKey, USER_TRACKING_ALL_PROPS } from '../hooks/use-user-tracking/types';

export const getAmplitudeSWJTiersParameters = (tiers: Array<JackpotTier> = []): Record<string, number> => {
    const validNames = ['Mini', 'Minor', 'Major', 'Mega'] as const;

    return tiers.reduce((params, tier) => {
        if (validNames.includes(tier.name)) {
            const key = getAmplitudeKey(`FDC_JACKPOT_${tier.name.toUpperCase()}_POT_AMOUNT` as keyof typeof USER_TRACKING_ALL_PROPS);

            params[key] = typeof tier.currentAmount === 'number' ? tier.currentAmount : 0;
        }
        return params;
    }, {} as Record<string, number>);
};
