import { Fragment } from 'preact';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    swjGameRulesSelector,
    swjOneLinerSelector,
    swjTCTextSelector,
    swjLogoSelector
} from '../../../../../selectors/site-wide-jackpot';
import { redirectToBrowser } from '../../../../../common/wrapper-bridge-mobile';
import { isMobile } from 'react-device-detect';
import { postCustomerPreferences } from '../../../../../redux/actions/customer-data/customer-data';
import { types } from '../../../../../redux/types';
import { useState } from 'preact/hooks';
require(`./styles/${process.env.APPLICATION}/style.scss`);
import checkmark from '../../../../../assets/icons/SWJ/checkmark.svg';
import { gameWindowSWJLatestTiers } from '../../../../../selectors/game-window';
import { getAmplitudeKey, MODULES } from '../../../../../common/hooks/use-user-tracking/types';
import { getAmplitudeSWJTiersParameters } from '../../../../../common/helpers/swjHelpers';
import { useLogTracking } from '../../../../../common/hooks/use-user-tracking/useUserTracking';

export const TCPopup = ({ toggleOptIn, userOptedIn, handleShowPopup, showTCPopup }) => {
    const dispatch = useDispatch();
    const jackpotRulesDefaultURL = `${window.location.origin.replace('launcher.', '')}/site-wide-jackpot-rules`;
    const jackpotRulesURL = useSelector(swjGameRulesSelector);
    const jackpotTiers = useSelector(gameWindowSWJLatestTiers);
    const { logTrackingComponent } = useLogTracking();
    const optedInOption = userOptedIn > 0 ? 'in' : 'out';
    const oneLiner = useSelector(swjOneLinerSelector);
    const tcDescription = useSelector(swjTCTextSelector);
    const forceTCPopup = useSelector(state => state?.customerData?.preferences?.showTCPopup);
    const customerData = useSelector(state => state?.customerData);

    const jackpotLogo = useSelector(swjLogoSelector);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleJackpotRulesLink = (e) => {
       if (!!isMobile && !!jackpotRulesURL) {
            e.preventDefault();
            redirectToBrowser(jackpotRulesURL);
        }
    }

    const handleToggle = async () => {
        setIsDisabled(true);
        try {
            if (forceTCPopup){
                postCustomerPreferences({ showTCPopupCounter: 3 });
                dispatch({
                    type: types.CUSTOMER_FEATURES_RECEIVED,
                    payload: {...customerData, preferences:{ ...customerData?.preferences, showSWJElements: true}}
                });
            }
            await toggleOptIn();

            logTrackingComponent({
                [getAmplitudeKey('MODULE')]: MODULES.GW,
                [getAmplitudeKey('EVENT')]: `FDC Jackpots Opt In ${optedInOption === 'in' ? 'out' : 'in'} Toggled`,
                ...getAmplitudeSWJTiersParameters(jackpotTiers)
            });
        } catch (error) { // promise rejected?
            console.error(error);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <Fragment>
            {showTCPopup && createPortal(
                <div className="tc-background" onClick={handleShowPopup} />,
                document.getElementById("game-launcher-wrapper")
            )}
            <div className={`tc-wrapper ${showTCPopup !== null ? (showTCPopup ? "open" : "closed") : ""}`}>
                <div className="header">
                    <img className="jackpot-logo-img" src={jackpotLogo} />
                </div>

                <div className="content">
                    <span className="title">{oneLiner}</span>
                    <div className="description">
                        {tcDescription}
                    </div>
                    <a
                      className="jackpot-rules-link"
                      target="_blank"
                      rel="noreferrer"
                      href={(!!jackpotRulesURL && !!isMobile) ? jackpotRulesURL : jackpotRulesDefaultURL}
                      onClick={handleJackpotRulesLink}>
                      Jackpot Rules
                    </a>
                </div>

                <div className="footer">
                    <div className="button-field">
                        {userOptedIn &&
                        <div className="button-field-checkmark-box">
                            <img className="button-field-checkmark" src={checkmark} />
                        </div>
                        }
                        <span className={`text-opted-${optedInOption}`}>{`You're opted ${optedInOption}`}</span>
                        <button disabled={isDisabled} className={`toggle-${userOptedIn === true ? 'on' : 'off'}`} onClick={handleToggle} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
